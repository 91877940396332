import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { key } from './store'
import { config } from './constants'
import sentry from './sentry'
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk'

const app = createApp(App)

initializeFaro({
  url: `https://faro-collector-prod-ap-southeast-0.grafana.net/collect/${config.faro.appKey}`,
  app: {
    name: 'InterSection',
    version: config.appVersion,
    environment: config.environment,
  },
  instrumentations: [...getWebInstrumentations()],
})

// Sentry
if (config.environment === 'production') sentry(app, router)

// Globally register all `_base`-prefixed components
import globals from '@/components/globals'
globals(app)

// ActionCable
import ActionCableVue from 'actioncable-vue'

const actionCableVueOptions = {
  debug: config.environment === 'development',
  debugLevel: 'error',
  connectImmediately: false,
  store,
}

// import './registerServiceWorker'

app
  .use(store, key)
  .use(router)
  .use(ActionCableVue, actionCableVueOptions)
  .mount('#app')

import Repository from './repository'

export default {
  logIn({ email, redirectTo = '/' }: { email?: string; redirectTo?: string } = {}) {
    return Repository({
      url: '/v1/auth/sign_in',
      method: 'post',
      data: { email, redirectTo, isMagic: true },
    })
  },

  validateToken({ admin = false } = {}) {
    return Repository({
      url: admin ? '/v1/admin/auth/validate_token' : '/v1/auth/validate_token',
      method: 'get',
    })
  },

  logOut({ admin = false } = {}) {
    return Repository({
      url: admin ? '/v1/admin/auth/sign_out' : '/v1/auth/sign_out',
      method: 'delete',
    })
  },

  signUp({ email, password }) {
    return Repository({
      url: '/v1/auth',
      method: 'post',
      data: { email, password },
    })
  },

  resetPassword({ email = undefined, redirectUrl = undefined, admin = false } = {}) {
    return Repository({
      url: admin ? '/v1/admin/auth/password' : '/v1/auth/password',
      method: 'post',
      data: { email, redirectUrl },
    })
  },

  updatePassword({ password = undefined, passwordConfirmation = undefined, admin = false } = {}) {
    return Repository({
      url: admin ? './v1/admin/auth/password' : '/v1/auth/password',
      method: 'patch',
      data: { password, passwordConfirmation },
    })
  },
}

// TODO: think about how to remove the save state bit
import { config } from '@/constants'
import { Reservation, SaunaKind } from '@/use/use-sauna-reservation-system'
import getSavedState from '@/utils/get-saved-state'
import saveState from '@/utils/save-state'
import { format } from 'date-fns'

type SaunaState = {
  reservation: Reservation | null
}

export const state: SaunaState = {
  reservation: getSavedState('sauna.reservation') || null,
}

export const getters = {
  reservation(state: SaunaState) {
    return state.reservation
  },
}

export const mutations = {
  SET_RESERVATION(state: SaunaState, reservation: SaunaState['reservation']) {
    state.reservation = reservation
    saveState('sauna.reservation', state.reservation)
  },
}

export const actions = {
  async makeReservation({ commit, rootState }, {
    saunaKind,
    date,
    time,
    numberOfPeople
  }: { saunaKind: SaunaKind, date: Date, time: string, numberOfPeople: number }) {
    const hyphenatedDate = format(date, 'yyyy-MM-dd')


    const url = new URL('/makeReservation', config.saunaReservationSystem.apiUrl)
    url.searchParams.set('type', saunaKind)
    url.searchParams.set('date', hyphenatedDate)
    url.searchParams.set('time', time)
    url.searchParams.set('numPeople', numberOfPeople.toString())

    const jwtToken = rootState.auth.currentUser.jwtToken
    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.status === 200) {
      const data = await response.json()
      commit('SET_RESERVATION', data)
      return { success: true, data }
    }

    return { success: false, data: response }
  },
  async cancelReservation({ commit, rootState }, { bookeoBookingNumber }: { bookeoBookingNumber: string }) {
    const url = new URL('/cancelReservation', config.saunaReservationSystem.apiUrl)
    url.searchParams.set('reservationNumber', bookeoBookingNumber)

    const jwtToken = rootState.auth.currentUser.jwtToken

    const response = await fetch(url.toString(), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.status === 200) {
      commit('SET_RESERVATION', null)
      return { success: true }
    }

    return { success: false }

  },
  async fetchLatestReservation({ commit, rootState }) {
    const url = new URL('/getLatestReservation', config.saunaReservationSystem.apiUrl)
    const jwtToken = rootState.auth.currentUser.jwtToken
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.status === 200) {
      const data = await response.json()
      commit('SET_RESERVATION', data)
      return { success: true, data }
    }

    return { success: false, data: response }
  }
}
